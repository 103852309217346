import React, { useState, useEffect, useRef } from 'react';
import { SlidersHorizontal, X, Search, Calendar, HelpCircle, MessageCircle } from 'lucide-react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import ValueSearchList from '../ValueSearchList';

const QuickFilter = ({ 
  onSubmit,
  onClose
}) => {
  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [filters, setFilters] = useState({});
  const [dateRange, setDateRange] = useState({ from: '', to: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [textQuery, setTextQuery] = useState('');
  const [indexedColumns, setIndexedColumns] = useState({});
  const [valueSearchSelections, setValueSearchSelections] = useState({}); 
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose]);

  const formatFieldName = (fieldName) => {
    return fieldName
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const getPlaceholderText = (fieldType) => {
    if (fieldType === 'FLOAT' || fieldType === 'INTEGER') {
      return 'Enter number';
    }
    return 'Enter text';
  };

  const formatTableName = (fullTableId) => {
    const tableName = fullTableId.split('.').pop();
    return tableName
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const fetchIndexedColumns = async () => {
    try {
      const indexingConfig = await getDoc(doc(db, 'appConfiguration', 'indexing'));
      if (indexingConfig.exists()) {
        setIndexedColumns(indexingConfig.data());
      }
    } catch (err) {
      console.error('Error fetching indexed columns:', err);
    }
  };

  useEffect(() => {
    fetchIndexedColumns();
  }, []);

  useEffect(() => {
    const fetchTables = async () => {
      try {
        setLoading(true);
        const configDoc = await getDoc(doc(db, 'appConfiguration', 'tables'));
        
        if (!configDoc.exists()) {
          throw new Error('Tables configuration not found');
        }

        const docData = configDoc.data();
        const tablesData = Object.entries(docData)
          .filter(([_, value]) => 
            typeof value === 'object' && 
            value !== null && 
            value.schema && 
            Array.isArray(value.schema)
          )
          .map(([name, tableData]) => ({
            name,
            id: tableData.id,
            schema: tableData.schema.filter(field => 
              !field.name.toLowerCase().includes('created') &&
              !field.name.toLowerCase().includes('modified') &&
              !field.name.toLowerCase().includes('timestamp') &&
              field.mode !== 'REPEATED'
            ),
            prompt: tableData.prompt || ''
          }));

        setTables(tablesData);
        if (tablesData.length > 0) {
          setSelectedTable(tablesData[0]);
        }
      } catch (err) {
        console.error('Error fetching tables:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTables();
  }, []);

  const handleTableChange = (tableName) => {
    const table = tables.find(t => t.name === tableName);
    setSelectedTable(table);
    setFilters({});
    setDateRange({ from: '', to: '' });
    setTextQuery('');
    setValueSearchSelections({}); // Reset value search selections when table changes
  };

  const handleFilterChange = (fieldName, value, isFromValueSearch = false) => {
    if (value === '') {
      const newFilters = { ...filters };
      delete newFilters[fieldName];
      setFilters(newFilters);
      
      const newValueSearchSelections = { ...valueSearchSelections };
      delete newValueSearchSelections[fieldName];
      setValueSearchSelections(newValueSearchSelections);
    } else {
      setFilters(prev => ({
        ...prev,
        [fieldName]: value
      }));
      
      if (isFromValueSearch) {
        setValueSearchSelections(prev => ({
          ...prev,
          [fieldName]: true
        }));
      }
    }
  };

  const handleDateRangeChange = (type, value) => {
    setDateRange(prev => ({
      ...prev,
      [type]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!selectedTable) return;
  
    let query = `SELECT * FROM \`${selectedTable.id}\` WHERE 1=1`;
    
    Object.entries(filters).forEach(([fieldName, value]) => {
      if (!value.trim()) return;
  
      const field = selectedTable.schema.find(f => f.name === fieldName);
      if (!field) return;
  
      if (field.type === 'STRING') {
        query += ` AND LOWER(${fieldName}) LIKE LOWER('%${value}%')`;
      } else if (['FLOAT', 'INTEGER'].includes(field.type)) {
        query += ` AND ${fieldName} = ${value}`;
      }
    });
  
    if (dateRange.from && dateRange.to) {
      const dateField = selectedTable.schema.find(f => 
        ['DATE', 'DATETIME', 'TIMESTAMP'].includes(f.type)
      )?.name;
  
      if (dateField) {
        query += ` AND DATE(${dateField}) BETWEEN '${dateRange.from}' AND '${dateRange.to}'`;
      }
    }
  
    let fullPrompt = `sql automatically formatted to focus the query:\n${query}`;
    if (textQuery.trim()) {
      fullPrompt += `\n\nSpecific question: ${textQuery.trim()}`;
    }
  
    const hasIndexedSelection = Object.keys(valueSearchSelections).length > 0;
  
    onSubmit(fullPrompt, { 
      isQuickFilter: true,
      isFromValueSearchList: hasIndexedSelection,
      isIndexed: hasIndexedSelection,             
      valueSearchSelections                       
    });
  };

  const buttonClass = textQuery.trim() 
    ? "w-full md:w-[120px] bg-gradient-to-r from-primary to-secondary text-white px-6 py-2 rounded-full hover:bg-opacity-90 focus:ring-2 focus:ring-offset-2 focus:ring-secondary transition-all duration-300 ease-in-out"
    : "w-full md:w-[120px] bg-secondary text-white px-6 py-2 rounded-full hover:bg-opacity-90 focus:ring-2 focus:ring-offset-2 focus:ring-secondary transition-all duration-300 ease-in-out";

  if (loading) {
    return (
      <div className="text-center p-4">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary mx-auto"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-red-500 text-center">
        <p>Error loading tables: {error}</p>
      </div>
    );
  }

  return (
    <div ref={modalRef} className="bg-gray-800 rounded-lg shadow-lg w-full max-w-4xl mx-auto h-[60vh] flex flex-col">
      <div className="p-6 border-b border-gray-700">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4 flex-1">
            <h3 className="text-lg font-semibold text-white">Select Table:</h3>
            <div className="flex-1 max-w-md">
              <select
                id="tableSelect"
                className="w-full bg-gray-700 text-white rounded-md px-4 py-2 focus:ring-2 focus:ring-secondary outline-none cursor-pointer"
                value={selectedTable?.name || ''}
                onChange={(e) => handleTableChange(e.target.value)}
              >
                {tables.map(table => (
                  <option key={table.name} value={table.name}>
                    {formatTableName(table.name)}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <X size={20} />
          </button>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="flex flex-col h-full">
        <div className="flex-1 overflow-y-auto p-6 space-y-6">
          {selectedTable && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {selectedTable.schema.map(field => {
                if (['DATE', 'DATETIME', 'TIMESTAMP'].includes(field.type)) {
                  return (
                    <div key={field.name} className="space-y-2">
                      <div className="flex items-center space-x-2">
                        <label className="block text-sm font-medium text-gray-300">
                          {formatFieldName(field.name)}
                        </label>
                        {field.description && (
                          <div className="relative group">
                            <HelpCircle size={16} className="text-gray-400 hover:text-white cursor-help" />
                            <div className="absolute left-0 bottom-full mb-2 hidden group-hover:block bg-gray-900 text-white text-xs p-2 rounded shadow-lg w-48 z-10">
                              {field.description}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="flex gap-2">
                        <div className="flex-1">
                          <input
                            type="date"
                            className="w-full bg-gray-700 text-white rounded-md px-4 py-2 focus:ring-2 focus:ring-secondary"
                            value={dateRange.from}
                            onChange={(e) => handleDateRangeChange('from', e.target.value)}
                          />
                        </div>
                        <div className="flex-1">
                          <input
                            type="date"
                            className="w-full bg-gray-700 text-white rounded-md px-4 py-2 focus:ring-2 focus:ring-secondary"
                            value={dateRange.to}
                            onChange={(e) => handleDateRangeChange('to', e.target.value)}
                            min={dateRange.from}
                          />
                        </div>
                      </div>
                    </div>
                  );
                }

                if (['RECORD', 'REPEATED'].includes(field.type)) {
                  return null;
                }

                return (
                  <div key={field.name}>
                    <div className="flex items-center space-x-2 mb-2">
                      <label className="block text-sm font-medium text-gray-300">
                        {formatFieldName(field.name)}
                      </label>
                      {field.description && (
                        <div className="relative group">
                          <HelpCircle size={16} className="text-gray-400 hover:text-white cursor-help" />
                          <div className="absolute left-0 bottom-full mb-2 hidden group-hover:block bg-gray-900 text-white text-xs p-2 rounded shadow-lg w-48 z-10">
                            {field.description}
                          </div>
                        </div>
                      )}
                    </div>
                    {indexedColumns[selectedTable.id] && 
                     indexedColumns[selectedTable.id].includes(field.name) ? (
                      <ValueSearchList
                        tableId={selectedTable.id}
                        columnName={field.name}
                        onSelect={(value) => handleFilterChange(field.name, value, true)}
                        placeholder={getPlaceholderText(field.type)}
                        className="bg-gray-700 text-white"
                        selectedValue={filters[field.name] || ''}
                        autoFocus={false}
                      />
                    ) : (
                      <input
                        type={field.type === 'FLOAT' || field.type === 'INTEGER' ? 'number' : 'text'}
                        className="w-full bg-gray-700 text-white rounded-md px-4 py-2 focus:ring-2 focus:ring-secondary"
                        value={filters[field.name] || ''}
                        onChange={(e) => handleFilterChange(field.name, e.target.value, false)}
                        placeholder={getPlaceholderText(field.type)}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className="p-6 border-t border-gray-700 bg-gray-800">
          <div className="flex flex-col md:flex-row md:items-center md:space-x-4 space-y-4 md:space-y-0">
            <div className="flex-1 relative">
              <img
                src="/favicon.ico"
                alt="Company Icon"
                className="absolute left-2.5 top-1/2 -translate-y-1/2 w-6 h-6"
              />
              <input
                type="text"
                className="w-full bg-gray-700 text-white rounded-full pl-12 pr-4 py-2 focus:outline-none focus:ring-2 focus:ring-secondary text-base placeholder-gray-400"
                value={textQuery}
                onChange={(e) => setTextQuery(e.target.value)}
                placeholder="Add a question... (optional)"
              />
            </div>
            <div className="md:w-auto">
              <button
                type="submit"
                className={buttonClass}
              >
                <div className="flex items-center justify-center space-x-2">
                  {textQuery.trim() ? (
                    <>
                      <MessageCircle size={16} />
                      <span>Ask</span>
                    </>
                  ) : (
                    <>
                      <Search size={16} />
                      <span>Search</span>
                    </>
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default QuickFilter;