import React, { useState, useEffect } from 'react';
import Card from './Card';
import Modal from './Modal';
import TextEditor from './TextEditor';
import { db, functions } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { Loader2, AlertCircle, RefreshCw, ChevronDown, ChevronRight, Plus } from 'lucide-react';
import { httpsCallable } from 'firebase/functions';
import { convertMarkdownToHtml, convertHtmlToMarkdown } from './markdownConverter';

const SchemaManagement = () => {
  // Existing state variables
  const [tables, setTables] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshing, setRefreshing] = useState({});
  const [expandedSections, setExpandedSections] = useState({});
  const [editingPrompt, setEditingPrompt] = useState(null);
  const [saveStatus, setSaveStatus] = useState({ tableId: null, type: '', message: '' });
  const [autoChecking, setAutoChecking] = useState(false);

  // New state variables for create table modal
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [newTableData, setNewTableData] = useState({
    name: '',
    id: '',
    prompt: ''
  });
  const [createLoading, setCreateLoading] = useState(false);

  // Existing functions (fetchTableSchema, checkSchemaChanges, etc.)
  const fetchTableSchema = async (tableId) => {
    try {
      setRefreshing(prev => ({ ...prev, [tableId]: true }));
      
      const getTableSchema = httpsCallable(functions, 'getTableSchema');
      const result = await getTableSchema({ tableId });
      
      return {
        schema: result.data.schema,
        schemaHash: result.data.schemaHash
      };
    } catch (error) {
      console.error(`Error fetching schema for ${tableId}:`, error);
      throw error;
    } finally {
      setRefreshing(prev => ({ ...prev, [tableId]: false }));
    }
  };

  const checkSchemaChanges = async (tableId, currentSchemaHash) => {
    try {
      setAutoChecking(true);
      const { schemaHash: newSchemaHash } = await fetchTableSchema(tableId);
      
      if (newSchemaHash !== currentSchemaHash) {
        // Schema has changed
        setSaveStatus({ 
          tableId, 
          type: 'warning', 
          message: 'Schema changes detected! Click refresh to update.' 
        });
      }

      // Update last check timestamp in Firestore
      const configDoc = doc(db, 'appConfiguration', 'tables');
      const tableName = tables.find(t => t.id === tableId)?.name;
      
      if (tableName) {
        await updateDoc(configDoc, {
          [`${tableName}.lastSchemaCheck`]: new Date().toISOString()
        });
      }
    } catch (err) {
      console.error('Error checking schema changes:', err);
    } finally {
      setAutoChecking(false);
    }
  };

  const fetchTables = async () => {
    try {
      setLoading(true);
      setError(null);
  
      const configDoc = doc(db, 'appConfiguration', 'tables');
      const docSnap = await getDoc(configDoc);
      
      if (!docSnap.exists()) {
        console.log('No document found at appConfiguration/tables');
        setError('No tables configuration found');
        return;
      }
  
      const docData = docSnap.data();
      console.log('Document data:', docData);
  
      const tablesData = Object.entries(docData)
        .filter(([_, value]) => typeof value === 'object' && value !== null)
        .map(([name, tableData]) => ({
          name,
          id: tableData.id || '',
          prompt: convertMarkdownToHtml(tableData.prompt || ''),
          schema: tableData.schema || [],
          schemaHash: tableData.schemaHash || '',
          lastSchemaCheck: tableData.lastSchemaCheck || null,
          dataSample: tableData.dataSample || [] // Handle missing dataSample
        }));
  
      setTables(tablesData);
  
      // Check for schema changes if last check was more than 24 hours ago
      const twentyFourHoursAgo = new Date();
      twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);
  
      for (const table of tablesData) {
        if (table.id) {
          const shouldCheckSchema = !table.lastSchemaCheck || 
                                  new Date(table.lastSchemaCheck) < twentyFourHoursAgo ||
                                  !table.schema || 
                                  table.schema.length === 0;
  
          if (shouldCheckSchema) {
            try {
              const getTableSchema = httpsCallable(functions, 'getTableSchema');
              const result = await getTableSchema({ tableId: table.id });
              const { schema, schemaHash, dataSample } = result.data;
              
              // Update state
              setTables(currentTables => 
                currentTables.map(t => 
                  t.id === table.id ? { 
                    ...t, 
                    schema,
                    schemaHash,
                    dataSample: dataSample || [], // Ensure dataSample is never undefined
                    lastSchemaCheck: new Date().toISOString()
                  } : t
                )
              );
  
              // Prepare update data
              const updateData = {
                [`${table.name}.schema`]: schema,
                [`${table.name}.schemaHash`]: schemaHash,
                [`${table.name}.lastSchemaCheck`]: new Date().toISOString()
              };
  
              // Only include dataSample if it exists and has data
              if (dataSample && dataSample.length > 0) {
                updateData[`${table.name}.dataSample`] = dataSample;
              }
  
              // Update Firestore
              await updateDoc(configDoc, updateData);
  
              // If schema hash is different, show warning
              if (schemaHash !== table.schemaHash) {
                setSaveStatus({ 
                  tableId: table.id, 
                  type: 'warning', 
                  message: 'Schema changes detected! Click refresh to update.' 
                });
              }
            } catch (err) {
              console.error(`Error fetching schema for ${table.id}:`, err);
              setTables(currentTables =>
                currentTables.map(t =>
                  t.id === table.id ? { ...t, schemaError: err.message } : t
                )
              );
            }
          }
        }
      }
    } catch (err) {
      console.error('Error fetching tables:', err);
      setError(err.message || 'Failed to fetch tables data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTables();
  }, []);

  const handleRefreshSchema = async (tableId) => {
    try {
      setSaveStatus({ tableId, type: 'loading', message: 'Refreshing schema...' });
      const getTableSchema = httpsCallable(functions, 'getTableSchema');
      const result = await getTableSchema({ tableId });
      const { schema, schemaHash, dataSample } = result.data;
      
      // Update state
      setTables(currentTables =>
        currentTables.map(t =>
          t.id === tableId ? { 
            ...t, 
            schema, 
            schemaHash,
            dataSample: dataSample || [], // Ensure dataSample is never undefined
            schemaError: null,
            lastSchemaCheck: new Date().toISOString()
          } : t
        )
      );
  
      // Find the table name from the current tables
      const tableName = tables.find(t => t.id === tableId)?.name;
      if (!tableName) {
        throw new Error('Table configuration not found');
      }
  
      // Save to Firestore with safe values
      const configDoc = doc(db, 'appConfiguration', 'tables');
      const updateData = {
        [`${tableName}.schema`]: schema || [],
        [`${tableName}.schemaHash`]: schemaHash || '',
        [`${tableName}.lastSchemaCheck`]: new Date().toISOString()
      };
  
      // Only include dataSample if it exists and has data
      if (dataSample && dataSample.length > 0) {
        updateData[`${tableName}.dataSample`] = dataSample;
      }
  
      await updateDoc(configDoc, updateData);
  
      setSaveStatus({ tableId, type: 'success', message: 'Schema updated successfully!' });
      setTimeout(() => setSaveStatus({ tableId: null, type: '', message: '' }), 3000);
    } catch (err) {
      console.error(`Error refreshing schema for ${tableId}:`, err);
      setSaveStatus({ tableId, type: 'error', message: 'Failed to update schema' });
      setTimeout(() => setSaveStatus({ tableId: null, type: '', message: '' }), 3000);
      setTables(currentTables =>
        currentTables.map(t =>
          t.id === tableId ? { ...t, schemaError: err.message } : t
        )
      );
    }
  };

  const handleSavePrompt = async (tableId, content) => {
    try {
      setSaveStatus({ tableId, type: 'loading', message: 'Saving...' });
      
      const configDoc = doc(db, 'appConfiguration', 'tables');
      const docSnap = await getDoc(configDoc);
      
      if (!docSnap.exists()) {
        throw new Error('Tables configuration not found');
      }
      
      const docData = docSnap.data();
      const tableData = docData[tables.find(t => t.id === tableId).name];
      
      if (!tableData) {
        throw new Error('Table configuration not found');
      }
      
      const markdownContent = convertHtmlToMarkdown(content);
      
      await updateDoc(configDoc, {
        [`${tables.find(t => t.id === tableId).name}.prompt`]: markdownContent
      });
      
      setTables(currentTables =>
        currentTables.map(t =>
          t.id === tableId ? { ...t, prompt: content } : t
        )
      );
      
      setSaveStatus({ tableId, type: 'success', message: 'Saved successfully!' });
      setTimeout(() => setSaveStatus({ tableId: null, type: '', message: '' }), 3000);
      setEditingPrompt(null);
    } catch (err) {
      console.error('Error saving prompt:', err);
      setSaveStatus({ tableId, type: 'error', message: 'Failed to save changes' });
      setTimeout(() => setSaveStatus({ tableId: null, type: '', message: '' }), 3000);
    }
  };

  const toggleSection = (tableId, section) => {
    if (section === 'prompt') {
      setEditingPrompt(editingPrompt === tableId ? null : tableId);
    }
    setExpandedSections(prev => ({
      ...prev,
      [`${tableId}-${section}`]: !prev[`${tableId}-${section}`]
    }));
  };

  // New function to handle table creation
  const handleCreateTable = async () => {
    try {
      setCreateLoading(true);

      if (!newTableData.name || !newTableData.id) {
        throw new Error('Table name and ID are required');
      }

      // Check if table name already exists
      const existingTable = tables.find(t => t.name === newTableData.name);
      if (existingTable) {
        throw new Error('A table with this name already exists');
      }

      const configDoc = doc(db, 'appConfiguration', 'tables');
      const docSnap = await getDoc(configDoc);
      
      if (!docSnap.exists()) {
        throw new Error('Tables configuration not found');
      }

      const updatedData = {
        [newTableData.name]: {
          id: newTableData.id,
          prompt: convertHtmlToMarkdown(newTableData.prompt || ''),
          schema: [],
          schemaHash: '',
          lastSchemaCheck: null
        }
      };

      await updateDoc(configDoc, updatedData);

      // Update local state
      setTables(currentTables => [...currentTables, {
        name: newTableData.name,
        id: newTableData.id,
        prompt: newTableData.prompt,
        schema: [],
        schemaHash: '',
        lastSchemaCheck: null
      }]);

      // Reset form and close modal
      setNewTableData({ name: '', id: '', prompt: '' });
      setIsCreateModalOpen(false);

      // Show success message
      setSaveStatus({
        tableId: 'new',
        type: 'success',
        message: 'Table created successfully!'
      });
      setTimeout(() => setSaveStatus({ tableId: null, type: '', message: '' }), 3000);

    } catch (err) {
      console.error('Error creating table:', err);
      setSaveStatus({
        tableId: 'new',
        type: 'error',
        message: err.message || 'Failed to create table'
      });
    } finally {
      setCreateLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-red-500">
        <h3 className="font-semibold mb-2">Error loading tables</h3>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Add Create Table Button */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Table Management</h2>
        <button
          onClick={() => setIsCreateModalOpen(true)}
          className="flex items-center px-4 py-2 text-sm text-gray-700 rounded-md hover:text-secondary transition-colors"
        >
          <Plus className="h-5 w-5 mr-2" />
          Create New Table
        </button>
      </div>
  
      {/* Create Table Modal */}
      <Modal
        isOpen={isCreateModalOpen}
        onClose={() => {
          setIsCreateModalOpen(false);
          setNewTableData({ name: '', id: '', prompt: '' });
        }}
        title="Create New Table"
        onSubmit={handleCreateTable}
        isLoading={createLoading}
        confirmText="Create Table"
        confirmDisabled={!newTableData.name || !newTableData.id}
      >
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Table Name
            </label>
            <input
              type="text"
              value={newTableData.name}
              onChange={(e) => setNewTableData(prev => ({ ...prev, name: e.target.value }))}
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="Enter table name"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Table ID
            </label>
            <input
              type="text"
              value={newTableData.id}
              onChange={(e) => setNewTableData(prev => ({ ...prev, id: e.target.value }))}
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="Enter table ID (e.g., project.dataset.table)"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Table Prompt (Optional)
            </label>
            <textarea
              value={newTableData.prompt}
              onChange={(e) => setNewTableData(prev => ({ ...prev, prompt: e.target.value }))}
              className="w-full p-2 border border-gray-300 rounded"
              rows={4}
              placeholder="Enter table prompt"
            />
          </div>
        </div>
      </Modal>
  
      {/* Existing table cards */}
      {tables.length === 0 ? (
        <Card>
          <div className="p-6">
            <p className="text-gray-700">No tables configuration found.</p>
          </div>
        </Card>
      ) : (
        tables.map((table) => (
          <Card key={table.name}>
            {/* Fixed Header Section */}
            <div className="p-4 border-b border-gray-200 sticky top-0 bg-white z-10">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-3">
                  <h3 className="text-card-title-base">{table.name}</h3>
                  {table.id && (
                    <div className="text-xs bg-opacity-20 bg-primary text-primary px-2 py-0.5 rounded-tag">
                      {table.id}
                    </div>
                  )}
                </div>
                {table.id && (
                  <button
                    onClick={() => handleRefreshSchema(table.id)}
                    disabled={refreshing[table.id]}
                    className="p-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                    title="Refresh schema"
                  >
                    <RefreshCw 
                      className={`h-5 w-5 ${refreshing[table.id] ? 'animate-spin' : ''}`}
                    />
                  </button>
                )}
              </div>
              {saveStatus.tableId === table.id && saveStatus.message && (
                <div className={`mt-2 text-sm ${
                  saveStatus.type === 'success' ? 'text-green-600' :
                  saveStatus.type === 'error' ? 'text-red-600' :
                  'text-blue-600'
                }`}>
                  {saveStatus.message}
                </div>
              )}
            </div>
  
            <div className="p-6">
              {/* Prompt Section */}
              {table.prompt && (
                <div className="mb-6">
                  <button
                    onClick={() => toggleSection(table.id, 'prompt')}
                    className="flex items-center text-base font-medium text-text-light mb-2 hover:text-text focus:outline-none"
                  >
                    {editingPrompt === table.id ? (
                      <ChevronDown className="h-4 w-4 mr-1" />
                    ) : (
                      <ChevronRight className="h-4 w-4 mr-1" />
                    )}
                    Prompt
                  </button>
                  {editingPrompt === table.id ? (
                    <div className="mb-4">
                      <TextEditor
                        title=""
                        initialContent={table.prompt}
                        onSave={(content) => handleSavePrompt(table.id, content)}
                        onCancel={() => {
                          setEditingPrompt(null);
                          setSaveStatus({ tableId: null, type: '', message: '' });
                        }}
                        height="h-[240px]"
                      />
                    </div>
                  ) : (
                    expandedSections[`${table.id}-prompt`] && (
                      <div
                        className="text-text-lighter text-sm whitespace-pre-wrap prose prose-sm max-w-none"
                        dangerouslySetInnerHTML={{ __html: table.prompt }}
                      />
                    )
                  )}
                </div>
              )}
  
              {/* Schema Section */}
              <div className="mb-6">
                <button
                  onClick={() => toggleSection(table.id, 'schema')}
                  className="flex items-center text-base font-medium text-text-light mb-2 hover:text-text focus:outline-none"
                >
                  {expandedSections[`${table.id}-schema`] ? (
                    <ChevronDown className="h-4 w-4 mr-1" />
                  ) : (
                    <ChevronRight className="h-4 w-4 mr-1" />
                  )}
                  Schema
                </button>
                {expandedSections[`${table.id}-schema`] && (
                  <>
                    {table.schemaError ? (
                      <div className="flex items-center space-x-2 text-red-500 mb-4">
                        <AlertCircle className="h-5 w-5" />
                        <p className="text-sm">{table.schemaError}</p>
                      </div>
                    ) : Array.isArray(table.schema) && table.schema.length > 0 ? (
                      <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th className="px-4 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
                                Column Name
                              </th>
                              <th className="px-4 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
                                Type
                              </th>
                              <th className="px-4 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
                                Mode
                              </th>
                              <th className="px-4 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
                                Description
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {table.schema.map((field, index) => (
                              <tr key={index} className="hover:bg-gray-50 transition-colors">
                                <td className="px-4 py-3 text-sm font-medium text-gray-900">
                                  {field.name}
                                </td>
                                <td className="px-4 py-3 text-sm text-gray-600">
                                  {field.type}
                                </td>
                                <td className="px-4 py-3 text-sm text-gray-600">
                                  {field.mode}
                                </td>
                                <td className="px-4 py-3 text-sm text-gray-600">
                                  {field.description || '-'}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p className="text-text-light text-sm">
                        {refreshing[table.id] ? 'Loading schema...' : 'No schema information available'}
                      </p>
                    )}
                  </>
                )}
              </div>
  
              {/* Sample Data Section */}
              <div>
                <button
                  onClick={() => toggleSection(table.id, 'sample')}
                  className="flex items-center text-base font-medium text-text-light mb-2 hover:text-text focus:outline-none"
                >
                  {expandedSections[`${table.id}-sample`] ? (
                    <ChevronDown className="h-4 w-4 mr-1" />
                  ) : (
                    <ChevronRight className="h-4 w-4 mr-1" />
                  )}
                  Sample Data
                </button>
                {expandedSections[`${table.id}-sample`] && (
                  <>
                    {table.dataSample && table.dataSample.length > 0 ? (
                      <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              {Object.keys(table.dataSample[0]).map((columnName) => (
                                <th
                                  key={columnName}
                                  className="px-4 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider"
                                >
                                  {columnName}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {table.dataSample.map((row, rowIndex) => (
                              <tr key={rowIndex} className="hover:bg-gray-50 transition-colors">
                                {Object.values(row).map((value, colIndex) => (
                                  <td
                                    key={colIndex}
                                    className="px-4 py-3 text-sm text-gray-600"
                                  >
                                    {value === null ? 'NULL' : String(value)}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p className="text-text-light text-sm">
                        {refreshing[table.id] ? 'Loading sample data...' : 'No sample data available'}
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
          </Card>
        ))
      )}
    </div>
  );
};

export default SchemaManagement;
