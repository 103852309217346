// TableView.js
import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ChevronUp, ChevronDown, Filter } from 'lucide-react';
import { sortData, formatColumnName, formatDate, formatNumber } from './dataProcessing';
import FilterModal from './FilterModal';
import _ from 'lodash';

const TableView = ({ 
  data, 
  schema, 
  initialSortColumn, 
  initialSortOrder, 
  onTextSelection,
  visibleColumns = [],
  columnOrder = [],
  onSortChange,
  enableFiltering = true, // Existing prop with default true for backward compatibility
  onFilterChange, // Existing optional callback for parent components to react to filter changes
  onProcessedDataChange, // Existing optional callback to notify parent of filtered/sorted data
  showSummary = false  // **New prop with default false**
}) => {
  const [sortConfig, setSortConfig] = useState({ 
    key: initialSortColumn || null, 
    direction: initialSortOrder || 'asc' 
  });
  const [tableHeight, setTableHeight] = useState('auto');
  const [tooltipContent, setTooltipContent] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ left: 0, width: 0 });
  const [activeFilters, setActiveFilters] = useState({});
  const [filterModalState, setFilterModalState] = useState({
    isOpen: false,
    columnName: null,
    position: { top: 0, left: 0 }
  });
  
  const headerRef = useRef(null);
  const tableRef = useRef(null);

  // Use columnOrder if provided, otherwise fall back to schema fields
  const displayColumns = useMemo(() => {
    if (columnOrder.length > 0) {
      return columnOrder.filter(col => visibleColumns.includes(col));
    }
    return schema?.fields?.map(field => field.name) || [];
  }, [columnOrder, visibleColumns, schema]);

  // Handle table height for scrolling
  useEffect(() => {
    if (headerRef.current && data.length > 7) {
      const headerHeight = headerRef.current.offsetHeight;
      const rowHeight = 40; // Standard row height
      setTableHeight(`${headerHeight + (rowHeight * 7)}px`);
    } else {
      setTableHeight('auto');
    }
  }, [data]);

  // Update sort config when initial values change
  useEffect(() => {
    setSortConfig({ key: initialSortColumn, direction: initialSortOrder });
  }, [initialSortColumn, initialSortOrder]);

  // Process data with filters and sorting
  const processedData = useMemo(() => {
    if (!Array.isArray(data) || data.length === 0) return [];
    
    // Apply filters first if filtering is enabled
    let filteredData = data;
    if (enableFiltering && Object.keys(activeFilters).length > 0) {
      filteredData = data.filter(row => {
        return Object.entries(activeFilters).every(([columnName, allowedValues]) => {
          const cellValue = row[columnName]?.displayValue ?? row[columnName];
          const stringValue = cellValue === null || cellValue === undefined 
            ? 'N/A' 
            : String(cellValue);
          return allowedValues.includes(stringValue);
        });
      });
    }

    // Then apply sorting
    const sortedData = sortData(filteredData, sortConfig.key, sortConfig.direction);

    // Notify parent component of processed data if callback provided
    if (onProcessedDataChange) {
      onProcessedDataChange(sortedData);
    }

    return sortedData;
  }, [data, activeFilters, sortConfig, enableFiltering, onProcessedDataChange]);

  const getFilteredDataForColumn = useCallback((columnName) => {
    if (!Array.isArray(data) || data.length === 0) return [];
    
    // Apply all filters except the current column's filter
    const otherFilters = Object.entries(activeFilters).filter(([key]) => key !== columnName);
    
    if (otherFilters.length === 0) return data;
    
    return data.filter(row => {
      return otherFilters.every(([filterCol, allowedValues]) => {
        const cellValue = row[filterCol]?.displayValue ?? row[filterCol];
        const stringValue = cellValue === null || cellValue === undefined 
          ? 'N/A' 
          : String(cellValue);
        return allowedValues.includes(stringValue);
      });
    });
  }, [data, activeFilters]);

  // Handle column sort request
  const requestSort = useCallback((key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    if (onSortChange) {
      onSortChange(key, direction);
    }
  }, [sortConfig, onSortChange]);

  // Handle filter icon click
  const handleFilterClick = useCallback((e, columnName) => {
    e.stopPropagation();
    const headerRect = e.currentTarget.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const modalWidth = 300; // Width of the modal
    const modalHeight = 500; // Max height of the modal
    
    // Calculate initial position
    let left = headerRect.left + window.scrollX;
    let top = headerRect.bottom + window.scrollY;
  
    // Adjust horizontal position if modal would overflow viewport
    if (left + modalWidth > viewportWidth) {
      left = Math.max(0, viewportWidth - modalWidth - 20); // 20px padding from right edge
    }
  
    // Adjust vertical position if modal would overflow viewport
    if (top + modalHeight > viewportHeight) {
      // Position above the header if there's more space available
      if (headerRect.top > modalHeight) {
        top = headerRect.top - modalHeight + window.scrollY;
      } else {
        // If not enough space above, position at top of viewport with small padding
        top = window.scrollY + 20;
      }
    }
  
    setFilterModalState({
      isOpen: true,
      columnName,
      position: { top, left }
    });
  }, []);

  // Handle filter application
  const handleApplyFilter = useCallback((columnName, selectedValues) => {
    const newFilters = { ...activeFilters };
    
    if (selectedValues.length === 0 || 
        (data.length > 0 && selectedValues.length === _.uniq(data.map(row => 
          String(row[columnName]?.displayValue ?? row[columnName])
        )).length)) {
      // If no values selected or all values selected, remove the filter
      delete newFilters[columnName];
    } else {
      newFilters[columnName] = selectedValues;
    }

    setActiveFilters(newFilters);
    if (onFilterChange) {
      onFilterChange(newFilters);
    }
  }, [activeFilters, data, onFilterChange]);

  // Close filter modal
  const closeFilterModal = useCallback(() => {
    setFilterModalState(prev => ({ ...prev, isOpen: false }));
  }, []);

  // Get sort icon for column header
  const getSortIcon = useCallback((columnName) => {
    if (sortConfig.key === columnName) {
      return sortConfig.direction === 'asc' ? 
        <ChevronUp className="inline-block w-4 h-4 ml-1" /> :
        <ChevronDown className="inline-block w-4 h-4 ml-1" />;
    }
    return null;
  }, [sortConfig]);

  // Get filter icon for column header
  const getFilterIcon = useCallback((columnName) => {
    if (!enableFiltering) return null;
    
    const isFiltered = activeFilters[columnName]?.length > 0;
    return (
      <Filter 
        size={14} 
        className={`inline-block ml-2 cursor-pointer transition-all
          ${isFiltered ? 'text-blue-600 scale-110 bg-blue-50 ring-2 ring-blue-200 p-0.5 rounded-full shadow-sm' : 'text-gray-400 hover:text-gray-600'}`}
        onClick={(e) => handleFilterClick(e, columnName)}
      />
    );
  }, [enableFiltering, activeFilters, handleFilterClick]);

  // Handle text selection
  const handleTextSelection = useCallback(() => {
    if (!onTextSelection) return;
    
    const selection = window.getSelection();
    const selectedText = selection.toString().trim();
    if (selectedText) {
      const range = selection.getRangeAt(0);
      const startNode = range.startContainer.parentElement;
      const endNode = range.endContainer.parentElement;
      
      const selectedColumns = new Set();
      let currentNode = startNode;
      while (currentNode && currentNode !== endNode.nextSibling) {
        if (currentNode.dataset && currentNode.dataset.column) {
          selectedColumns.add(currentNode.dataset.column);
        }
        currentNode = currentNode.nextSibling || (currentNode.parentNode && currentNode.parentNode.nextSibling);
      }
      
      const rect = range.getBoundingClientRect();
      const selectionData = {
        text: selectedText,
        columns: Array.from(selectedColumns),
        position: {
          x: rect.left + rect.width / 2,
          y: rect.top - 20
        }
      };
      
      onTextSelection(selectionData);
    }
  }, [onTextSelection]);

  // Handle cell value rendering
  const renderCellValue = useCallback((cellData, columnName) => {
    if (!cellData || cellData.displayValue === null || cellData.displayValue === undefined) {
      return 'N/A';
    }
    return cellData.displayValue;
  }, []);

  // Calculate column statistics for tooltip
  const calculateColumnStats = useCallback((columnName) => {
    const values = processedData
      .map(row => parseFloat(row[columnName].sortValue))
      .filter(val => !isNaN(val));
    
    if (values.length === 0) return null;

    const sum = values.reduce((acc, val) => acc + val, 0);
    const avg = sum / values.length;
    const min = Math.min(...values);
    const max = Math.max(...values);

    return {
      sum: formatNumber(sum, columnName),
      avg: formatNumber(avg, columnName),
      min: formatNumber(min, columnName),
      max: formatNumber(max, columnName)
    };
  }, [processedData]);

  // Handle header hover for tooltips
  const handleHeaderHover = useCallback((event, columnName) => {
    if (processedData.length <= 1) {
      setTooltipContent(null);
      return;
    }

    const schemaType = schema?.fields?.find(f => f.name === columnName)?.type;
    if (schemaType && ['INTEGER', 'FLOAT', 'NUMERIC'].includes(schemaType)) {
      const stats = calculateColumnStats(columnName);
      if (stats) {
        setTooltipContent(
          <div className="p-2 text-sm">
            <div>Sum: {stats.sum}</div>
            <div>Avg: {stats.avg}</div>
            <div>Min: {stats.min}</div>
            <div>Max: {stats.max}</div>
          </div>
        );
        const headerCell = event.target;
        const headerRect = headerCell.getBoundingClientRect();
        const tableRect = tableRef.current.getBoundingClientRect();
        setTooltipPosition({ 
          left: headerRect.left - tableRect.left, 
          width: headerRect.width 
        });
      }
    }
  }, [processedData, schema, calculateColumnStats]);

  const handleHeaderLeave = useCallback(() => {
    setTooltipContent(null);
  }, []);

  // **New Function: Determine if a column is numeric**
  const isNumericColumn = useCallback((columnName) => {
    const field = schema?.fields?.find(f => f.name === columnName);
    return field && ['INTEGER', 'FLOAT', 'NUMERIC'].includes(field.type);
  }, [schema]);

  if (!Array.isArray(data) || data.length === 0) return <div>No data available</div>;

  return (
    <div className="relative" ref={tableRef}>
      <div className="overflow-x-auto" style={{ height: tableHeight, position: 'relative' }}>
        <table className="w-full border-collapse border border-gray-300">
          {/* Summary Statistics Row */}
          {showSummary && (
            <thead className="sticky top-0 bg-gray-50 border-b border-gray-300" style={{ zIndex: 3 }}>
              <tr className="divide-x divide-gray-300">
                {displayColumns.map(columnName => {
                  if (isNumericColumn(columnName)) {
                    const stats = calculateColumnStats(columnName);
                    return stats ? (
                      <th key={`summary-${columnName}`} className="p-2 align-top">
                        <div className="space-y-2 text-sm text-left">
                          <div className="flex items-center">
                            <span className="text-gray-700">Sum:</span>
                            <span className="ml-2 font-semibold text-gray-900">{stats.sum}</span>
                          </div>
                          <div className="flex items-center">
                            <span className="text-gray-700">Avg:</span>
                            <span className="ml-2 font-semibold text-gray-900">{stats.avg}</span>
                          </div>
                        </div>
                      </th>
                    ) : (
                      <th key={`summary-${columnName}`} className="p-2"></th>
                    );
                  }
                  return <th key={`summary-${columnName}`} className="p-2"></th>;
                })}
              </tr>
            </thead>
          )}
          
          {/* Column Headers */}
          <thead 
            ref={headerRef} 
            className="sticky bg-gray-100" 
            style={{ 
              top: showSummary ? '64px' : 0,
              zIndex: 2 
            }}
          >
            <tr>
              {displayColumns.map(columnName => (
                <th 
                  key={columnName} 
                  className="border border-gray-300 p-2 text-sm font-semibold text-left cursor-pointer hover:bg-gray-200 transition-colors"
                  onMouseEnter={(e) => handleHeaderHover(e, columnName)}
                  onMouseLeave={handleHeaderLeave}
                  onClick={() => requestSort(columnName)}
                >
                  <div className="flex items-center justify-between">
                    <span>
                      {formatColumnName(columnName)}
                      {getSortIcon(columnName)}
                    </span>
                    {data.length > 2 && getFilterIcon(columnName)}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
  
          {/* Table Body */}
          <tbody className="overflow-y-auto" onMouseUp={handleTextSelection}>
            {processedData.map((row, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : ''}>
                {displayColumns.map((columnName) => (
                  <td 
                    key={columnName} 
                    className="border border-gray-300 p-2 text-sm text-left"
                    data-column={columnName}
                  >
                    {renderCellValue(row[columnName], columnName)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
  
      {/* Tooltip */}
      {tooltipContent && processedData.length > 1 && (
        <div
          className="absolute bg-white border border-gray-300 rounded shadow-md z-10"
          style={{
            left: `${tooltipPosition.left}px`,
            width: `${tooltipPosition.width}px`,
            top: `${headerRef.current.offsetHeight}px`,
          }}
        >
          {tooltipContent}
        </div>
      )}
  
      {/* Filter Modal */}
      {enableFiltering && (
        <FilterModal 
          isOpen={filterModalState.isOpen}
          onClose={closeFilterModal}
          columnName={filterModalState.columnName}
          data={filterModalState.columnName ? getFilteredDataForColumn(filterModalState.columnName) : data}  // NEW LINE
          activeFilters={activeFilters}
          onApplyFilter={handleApplyFilter}
          position={filterModalState.position}
          schema={schema}
          style={{
            maxHeight: '80vh',
            overflowY: 'auto'
          }}
        />
      )}
    </div>
  );
};

TableView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  schema: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string
    }))
  }),
  initialSortColumn: PropTypes.string,
  initialSortOrder: PropTypes.oneOf(['asc', 'desc']),
  onTextSelection: PropTypes.func,
  visibleColumns: PropTypes.arrayOf(PropTypes.string),
  columnOrder: PropTypes.arrayOf(PropTypes.string),
  onSortChange: PropTypes.func,
  enableFiltering: PropTypes.bool,
  onFilterChange: PropTypes.func,
  onProcessedDataChange: PropTypes.func,
  showSummary: PropTypes.bool, // **New PropType**
};

TableView.defaultProps = {
  visibleColumns: [],
  columnOrder: [],
  enableFiltering: true,
  showSummary: false, // **New Default Prop**
};

export default TableView;
